import React from "react"
import { DownloadPDFButton, FileSize, Wrapper } from "./style"
import { Icon } from "UI"
import { useMutation } from "@apollo/react-hooks"
import { TRACK_EVENT } from "helpers/activeCampaign"

// function downloadPDF(pdfUrl, fileName) {
//   const link = document.createElement("a")
//   link.href = pdfUrl
//   link.download = fileName // You can specify a file name here
//   document.body.appendChild(link)
//   link.click()
//   document.body.removeChild(link) // Clean up after download
// }

const DownloadPDF = ({ isBottom, mediaItemUrl, fileSize, title }) => {
  const [trackEvent] = useMutation(TRACK_EVENT)
  if (!mediaItemUrl) return null
  const kb = Math.round(fileSize / 1000)

  //   onClick={() => downloadPDF(mediaItemUrl, `${title}.pdf`)}

  return (
    <Wrapper isBottom={isBottom}>
      <DownloadPDFButton
        href={mediaItemUrl}
        target="_blank"
        onClick={() => {
          trackEvent({
            variables: {
              email: localStorage.getItem("activeCampaignEmail") || "",
              data: getFilenameFromUrl(mediaItemUrl),
              type: "Download",
            },
          })
        }}
      >
        <Icon name="acrobat" size="24px" />
        Download PDF version
      </DownloadPDFButton>
      <FileSize
        href={mediaItemUrl}
        target="_blank"
        onClick={() => {
          trackEvent({
            variables: {
              email: localStorage.getItem("activeCampaignEmail") || "",
              data: getFilenameFromUrl(mediaItemUrl),
              type: "Download",
            },
          })
        }}
      >
        <span>
          <strong>File size:</strong> {kb}KB
        </span>
        <Icon name="download" size="24px" />
      </FileSize>
    </Wrapper>
  )
}

function getFilenameFromUrl(url) {
  try {
    const urlObject = new URL(url)
    const pathname = urlObject.pathname
    const segments = pathname.split("/")
    return segments.pop()
  } catch (error) {
    console.error("Invalid URL", error)
    return null
  }
}

export default DownloadPDF
